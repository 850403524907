
import { defineComponent } from "vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";

export default defineComponent({
  name: "List",
  components: {TabNavigate},
  setup() {

  }
})
